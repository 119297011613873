<template>
  <div>
    <c-tab
      ref="patrolTab"
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :count="count"
          @closePopup="closePopup"
          @changeStatus="changeStatus"
          @transInfo="transInfo"
        />
      </template>
    </c-tab>
  </div>
      <!-- class="full-height" -->
</template>

<script>
export default {
  name: 'cto-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolVendorId: '',
        updateFlag: '',
        vendorCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      height: '',
      editable: true,
      splitter: 5,
      tab: 'patrolDetail',
      count: 0,
      patrol: {
        saiPatrolVendorId: '',
      },
      addTabItems: [],
      tabItems: [],
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.tabItems = [
        { name: 'patrolDetail', icon: 'edit', label: '순회점검 정보', component: () => import(`${'./patrolDetail.vue'}`) },
      ];
      this.addTabItems = [
        { name: 'patrolImprove', icon: 'construction', label: '개선관리', component: () => import(`${'./patrolImprove.vue'}`) },
      ];
      if (this.popupParam.saiPatrolVendorId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
    transInfo(data) {
      this.popupParam.saiPatrolVendorId = data.saiPatrolVendorId;
      this.popupParam.vendorCd = data.vendorCd;
    },
    changeStatus() {
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    tabClick() {
      this.count++;
    },
  }
};
</script>
